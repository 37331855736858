@import url(https://fonts.googleapis.com/css2?family=Gaegu:wght@300;400;700&display=swap);
body {
  margin: 0;
  font-family: 'Gaegu', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App{font-family:"Gaegu",sans-serif;background-color:#e1d9cc;color:#867762;width:100vw;height:100vh;display:grid;place-items:center}.App .info{text-align:center;margin:1rem;-webkit-user-select:none;-ms-user-select:none;user-select:none;width:30vw}.App .info h1{font-weight:bold;font-size:32px;margin-bottom:1rem}.App .info p{font-size:24px;margin-bottom:3rem}.App .info .socials .icon{margin:1.5rem;height:25px;width:auto;color:#867762}.App .info .socials .icon:hover{transform:translateY(2px) !important}@media screen and (max-width: 800px){.App .info{width:90vw}.App .info .socials .icon{margin:1rem}}
